import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { format, subDays } from "date-fns";
import CurrencyInput from 'react-currency-input-field';
import * as Yup from 'yup';
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Button,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import { ContratoTab } from "../../contratoTab";
import { ServicoTab } from "../../servicoTab";
import { BoxBase, CustomTabPanel, FormControlBase, FormSectionBox, FormSectionTitle, InputLabelSelect, SelectBase, tabProps, TextFieldBase, ButtonBase, CheckboxBase } from "../../../styles";
import { PedidosContext } from "../../../../../Context/PedidosContext";
import { MyContext } from "../../../../../Context/MyContext";

import { InscricoesAdicionais } from "../../inscricoesAdicionais";
import { ModalLiberacao } from "../../modalLiberacao";

import { Edit } from "@mui/icons-material";
import Loading from "../../../../../Components/Loading";
import Mensagem from "../../../../../Components/Mensagem";
import ModalNS from "../../../../../Components/ModalNS";
import api from "../../../../../Api/apiAxios";

export function PedidoPonto({ isShow = false}) {
  const [value, setValue] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [listaNS, setListaNS] = useState([]);
  const [selectedNS, setSelectedNS] = useState(false);
  const [showModalNS, setShowModalNS] = useState(false);
  const [showMensagem, setShowMensagem] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [tipoMensagem, setTipoMensagem] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const navigate = useNavigate();

  const { id } = useParams();
  const { 
    distribuidores, 
    modalLiberacao, 
    setModalLiberacao, 
    setDadosTabs, 
    motivosCancelamento, 
    pedidoLiberado, 
    setPedidoLiberado,
    parseNumberCurrency,
  } = useContext(PedidosContext);
  const { setMensagemGlobal, setTipoMensagemGlobal } = useContext(MyContext);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleDistribuidorChange(e) {
    formik.handleChange(e);
    formik.setFieldValue('cliente', 0);
    formik.setFieldValue('nomeFantasia', '');
    formik.setFieldValue('numeroSerie', 0);
    setListaNS([])
  }

  async function handleClienteChange(e) {
    formik.handleChange(e);
    formik.setFieldValue('nomeFantasia', '');
    formik.setFieldValue('numeroSerie', 0);
    setListaNS([])

    const idCliente = e.target.value;

    const response = await api.get(`/Cliente/${idCliente}`);

    formik.setFieldValue('nomeFantasia', response.data.nomeFantasia);
    formik.setFieldValue('numeroInscricao', response.data.numeroInscricao);

    if (formik.values.tipoPedido === 1) {
      const res = await api.get(`/LicencaPonto/retornar-ns-cliente?id=${response.data.id}`);
      const qtdRegistros = res.data.length;
      formik.setFieldValue('quantidadeLicencas', qtdRegistros);
      
      if (qtdRegistros === 1) {
        loadFields(res.data[0], false)
      } else if (qtdRegistros > 1) {
        setListaNS(res.data);
      }
    }
  };

  async function handleVerificarTotaisClick() {
    setIsSaveEnabled(true);
    
    let regPedido = {
      Id: id || 0,
      FaixaFuncionarios: Number(formik.values.faixaFuncionarios) || 0,            
      Portaria671: formik.values.portaria671 ? 1 : 0,       
      IntegradorRep: formik.values.integradorREP ? 1 : 0,       
      Supervisor: formik.values.tipoPlano === "2" ? 1 : 0,
      PortalColaborador: formik.values.tipoPlano === "2" ? 1 : 0,
      AplicativoRegistrador: formik.values.tipoPlano === "2" ? 1 : 0,
      ColetorVirtual: 0,
      QuantidadeColetorVirtual: 0,
      UsuariosAdicionais: Number(formik.values.usuariosAdicionais) || 0,
      ModuloEpi: formik.values.moduloEPI ? 1 : 0,
      DistribuidorId: Number(formik.values.distribuidor),    
      TipoPedido: Number(formik.values.tipoPedido),
      ClienteId: Number(formik.values.cliente),    
      NumeroSerie: Number(formik.values.numeroSerie),
      StatusPedido: formik.values.statusPedido,
      TipoBancoDados: Number(formik.values.bancoDeDados),
      TipoInstalacao: Number(formik.values.tipoPlano),
      VersaoSistema: formik.values.versao?.toString().includes('.')
        ? formik.values.versao
        : formik.values.versao.toString().substring(0, 2) + 
          (formik.values.versao.toString().substring(2).length > 0 
            ? '.' + formik.values.versao.toString().substring(2) 
            : ''),
      VersaoRelease: formik.values.release ? formik.values.release.toString() : '',
      DataPedido: new Date(),
      Observacao: formik.values.observacoes,
      MotivoCancelamento: '',
      NumeroOrdemCompra: '',
      ValorBruto: 0,
      ValorDesconto: 0,
      ValorLiquido: 0,
      ValorMensal: parseNumberCurrency(formik.values.valorMensal),
      IntegracaoAcesso: formik.values.acesso ? 1 : 0,
      IntegracaoFolha: formik.values.folha ? 1 : 0,
      IntegracaoPonto: 0,
      Contrato: formik.values.contrato ? 1 : 0,
      EnviarEmailPedidoLiberado: 0,
      DataInicioFaturamento: formik.values.inicioFaturamento,
    };    
    
    const ret = await api.post("CalculoPonto/", JSON.stringify(regPedido), { headers: { 'Content-Type': 'application/json'} });

    formik.setFieldValue('valorPedido', ret.data.valorBruto);
    formik.setFieldValue('valorMensal', ret.data.valorMensal);
    calcValorParcela();
  }
  
  async function atualizarLicencaPorNS() {
    const response = await api.get(`/LicencaPonto/retornar-licenca?numeroSerie=${formik.values.numeroSerie}&inscricao=${formik.values.numeroInscricao}`);

    loadFields(response.data, false)

    setSelectedNS(false);
  }

  const handleSave = async () => {
    setMensagemGlobal(null);
    setTipoMensagemGlobal('')
    setShowMensagem(false)

    if (nivelUsuario === '1') handleVerificarTotaisClick();

    const regPedido = {
      Id: id || 0,
      FaixaFuncionarios: Number(formik.values.faixaFuncionarios) || 0,            
      Portaria671: formik.values.portaria671 ? 1 : 0,       
      IntegradorRep: formik.values.integradorREP ? 1 : 0,       
      Supervisor: formik.values.tipoPlano === "2" ? 1 : 0,
      PortalColaborador: formik.values.tipoPlano === "2" ? 1 : 0,
      AplicativoRegistrador: formik.values.tipoPlano === "2" ? 1 : 0,
      ColetorVirtual: 0,
      QuantidadeColetorVirtual: 0,
      UsuariosAdicionais: Number(formik.values.usuariosAdicionais) || 0,
      ModuloEpi: formik.values.moduloEPI ? 1 : 0,
      DistribuidorId: Number(formik.values.distribuidor),    
      TipoPedido: Number(formik.values.tipoPedido),
      ClienteId: Number(formik.values.cliente),    
      NumeroSerie: Number(formik.values.numeroSerie),
      StatusPedido: formik.values.statusPedido,
      TipoBancoDados: Number(formik.values.bancoDeDados),
      TipoInstalacao: Number(formik.values.tipoPlano),
      VersaoSistema: formik.values.versao.toString().includes('.')
        ? formik.values.versao
        : formik.values.versao.toString().substring(0, 2) + 
          (formik.values.versao.toString().substring(2).length > 0 
            ? '.' + formik.values.versao.toString().substring(2) 
            : ''),
      VersaoRelease: formik.values.release,
      DataPedido: new Date(),
      Observacao: formik.values.observacoes,
      MotivoCancelamento: '',
      NumeroOrdemCompra: '',
      ValorBruto: parseNumberCurrency(formik.values.valorPedido),
      ValorDesconto: 0,
      ValorLiquido: 0,
      ValorMensal: parseNumberCurrency(formik.values.valorMensal),
      IntegracaoAcesso: formik.values.acesso ? 1 : 0,
      IntegracaoFolha: formik.values.folha ? 1 : 0,
      IntegracaoPonto: 0,
      Contrato: formik.values.contrato ? 1 : 0,
      QuantidadeParcelas: 1,
      EnviarEmailPedidoLiberado: 0,
      DataInicioFaturamento: formik.values.inicioFaturamento,
      DataCancelamento: formik.values.dataCancelamento,
      motivoCancelamentoOpcao: formik.values.motivoCancelamentoOpcao,
      motivoCancelamento: formik.values.motivoCancelamento,
    };

    try {
      let ret;
      
      if (id) {
        ret = await api.put("BmaPonto/", JSON.stringify(regPedido), { headers: { 'Content-Type': 'application/json'} });
      } else {
        ret = await api.post("BmaPonto/", JSON.stringify(regPedido), { headers: { 'Content-Type': 'application/json'} });
      }      

      if (!id && ret.data.id > 0) {
        setMensagem('Pedido incluído com sucesso!');
        setTipoMensagem("success");
        setShowMensagem(true);
        navigate(`/viewpedidoponto/${ret.data.id}`)
      } else if (id) {
        setMensagem('Pedido alterado com sucesso!');
        setTipoMensagem("success");
        setShowMensagem(true);
        navigate(`/viewpedidoponto/${ret.data.id}`)
      }

    } catch (error) {
      console.error(error);
      setMensagem(error)
      setTipoMensagem("danger");
      setShowMensagem(true);
    }
  };

  const handleSelecionarNS = () => { 
    setSelectedNS(false)
    
    if (listaNS.length > 0) setShowModalNS(true) 
  }

  const loadFields = (values, allFields) => { 
    console.log(values)
    if (allFields) {
      formik.setFieldValue('tipoPedido', values.tipoPedido);
      formik.setFieldValue('statusPedido', values.statusPedido);
      formik.setFieldValue('distribuidor', values.distribuidorId);
      formik.setFieldValue('cliente', values.clienteId);
      formik.setFieldValue('nomeFantasia', values.cliente.nomeFantasia);
      formik.setFieldValue('razaoSocial', values.cliente.razaoSocial);
      formik.setFieldValue('numeroInscricao', values.cliente.numeroInscricao);
      formik.setFieldValue('observacoes', values.observacao);
      formik.setFieldValue('inicioFaturamento', format(new Date(values.dataInicioFaturamento), 'yyyy-MM-dd'));
      formik.setFieldValue('quantidadeParcelas', values.quantidadeParcelas);
      formik.setFieldValue('valorPedido', values.valorBruto);
      formik.setFieldValue('valorMensal', values.valorMensal);
      formik.setFieldValue('portaria671', values.portaria671);
      formik.setFieldValue('integradorREP', values.integradorRep);
      formik.setFieldValue('moduloEPI', values.moduloEpi);
      formik.setFieldValue('licenciado', values.tipoInstalacao === 0);
    }

    formik.setFieldValue('numeroSerie', values.numeroSerie);
    formik.setFieldValue('tipoPlano', values.tipoInstalacao);
    formik.setFieldValue('bancoDeDados', values.tipoBancoDados);
    formik.setFieldValue('faixaFuncionarios', values.faixaFuncionarios);
    formik.setFieldValue('usuariosAdicionais', values.quantidadeUsuarios - 1 || values.usuariosAdicionais);
    formik.setFieldValue('portaria671', values.portaria671 === 1);
    formik.setFieldValue('integradorREP', values.integradorRep === 1);
    formik.setFieldValue('moduloEPI', values.moduloEpi === 1);
    formik.setFieldValue('acesso', values.integracaoAcesso === 1);
    formik.setFieldValue('folha', values.integracaoFolha === 1);
    formik.setFieldValue('contrato', values.temContrato === 1 || values.contrato === 1);
    formik.setFieldValue('motivoCancelamentoOpcao', values.motivoCancelamentoOpcao);
    formik.setFieldValue('motivoCancelamento', values.motivoCancelamento);
    if (values.dataInicioFaturamento !== null) formik.setFieldValue('inicioFaturamento', format(new Date(values.dataInicioFaturamento), 'yyyy-MM-dd'));
    if (values.dataCancelamento !== null) formik.setFieldValue('dataCancelamento', format(new Date(values.dataCancelamento), 'yyyy-MM-dd'));

    calcValorParcela();
  };
  

  const validationSchema = Yup.object({
    tipoPedido: Yup.number().required('Tipo de pedido é obrigatório'),
    statusPedido: Yup.number().required('Status do pedido é obrigatório')
      .min(0)
      .nullable(false),
    distribuidor: Yup.number().required('Revenda é obrigatória'),
    cliente: Yup.number().required('Cliente é obrigatório'),
    nomeFantasia: Yup.string().required('Nome fantasia é obrigatório'),
    razaoSocial: Yup.string().nullable(),
    numeroInscricao: Yup.string().nullable(),
    numeroSerie: Yup.string().nullable(),
    tipoPlano: Yup.number()
      .required('Tipo de plano é obrigatório')
      .min(0)
      .nullable(false),
    bancoDeDados: Yup.string().required('Banco de dados é obrigatório'),
    observacoes: Yup.string().max(500, 'Observações não podem ter mais de 500 caracteres'),
    inicioFaturamento: Yup.date().required('O início do faturamento é obrigatório.'),
    faixaFuncionarios: Yup.number()
      .min(10, 'Faixa de funcionários deve ser maior ou igual a 10')
      .required('Faixa de funcionários é obrigatória'),
    usuariosAdicionais: Yup.number().nullable(),
    quantidadeParcelas: Yup.number()
      .min(1, 'Quantidade de parcelas deve ser no mínimo 1')
      .required('Quantidade de parcelas é obrigatória'),
    quantidadeLicencas: Yup.number().nullable(),
    valorParcela: Yup.string().nullable(),
    valorPedido: Yup.string().nullable(),
    valorMensal: Yup.string().nullable(),
    portaria671: Yup.boolean(),
    integradorREP: Yup.boolean(),
    moduloEPI: Yup.boolean(),
    licenciado: Yup.boolean(),
    acesso: Yup.boolean(),
    folha: Yup.boolean(),
    contrato: Yup.boolean(),
    versao: Yup.string().nullable(),
    release: Yup.string().nullable(),
    dataCancelamento: Yup.date().nullable().min(subDays(new Date(), 1), 'A data de cancelamento deve ser a partir de hoje.'),
    motivoCancelamentoOpcao: Yup.number().nullable(),
    motivoCancelamento: Yup.string().nullable(),
});

  const formik = useFormik({
    initialValues: {
      tipoPedido: null,
      statusPedido: 0,
      distribuidor: null,
      cliente: null,
      nomeFantasia: '',
      razaoSocial: '', 
      numeroInscricao: '',
      numeroSerie: null,
      tipoPlano: null, 
      bancoDeDados: null,
      observacoes: '',
      versao: null,
      release: null,
      inicioFaturamento: format(new Date(), 'yyyy-MM-dd'),
      faixaFuncionarios: 25,
      usuariosAdicionais: 0,
      quantidadeParcelas: 1,
      quantidadeLicencas: 0,
      valorParcela: "0,00",
      valorPedido: "0,00",
      valorMensal: "0,00",
      portaria671: true,
      integradorREP: false,
      moduloEPI: false,
      licenciado: false,
      acesso: false,
      folha: false,
      contrato: true,
      dataCancelamento: null, 
      motivoCancelamentoOpcao: 0,
      motivoCancelamento: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  })

  const handleFaixaFuncionariosChange = (e) => {
    const value = parseInt(e.target.value, 10);
    
    if (isTyping) {
      formik.handleChange(e);
      setIsTyping(false);
      return;
    }
  
    const faixaMap = {
      11: 25,
      26: 50,
      51: 75,
      76: 100,
      99: 75,
      74: 50,
      49: 25,
      24: 10
    };
  
    formik.setFieldValue('faixaFuncionarios', faixaMap[value] || (value >= 100 ? value : formik.values.faixaFuncionarios));
  };

  const calcValorParcela = () => {
    if (formik.values.quantidadeParcelas < 1) return;

    const newValorParcela = parseNumberCurrency(formik.values.valorPedido) / formik.values.quantidadeParcelas;
    formik.setFieldValue("valorParcela", newValorParcela);
  }

  const handleContratoChange = (e) => {
    if (formik.values.tipoPlano === 1 || formik.values.tipoPlano === 2) {
      formik.setFieldValue('contrato', true);
    } else {
      formik.handleChange(e);
    }
  }

  useEffect(() => {
    const loadData = async () => {
     if (!id) return;
 
     try {
       setIsLoading(true)
       const response = await api.get(`/BmaPonto/${id}`);
       loadFields(response.data, true)
     } catch (e) {
       console.log(e)
     } finally {
       setIsLoading(false)
     }
    }
 
    loadData();
   }, [id])
  
  useEffect(() => {
    const getClientes = async (id) => {
      if (!id) return;
  
      try {
        const { data } = await api.get(`/Cliente/${id}/A`);
        
        const registrosFiltrados = data
          .filter((cliente) => cliente.statusEmpresa === 1)
          .sort((a, b) => a.razaoSocial.localeCompare(b.razaoSocial));
  
        setClientes(registrosFiltrados);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    };
    getClientes(formik.values.distribuidor);
  }, [formik.values.distribuidor]);

  useEffect(() => {
    if (selectedNS) atualizarLicencaPorNS();
  }, [selectedNS])

  useEffect(() => {
    async function getVersaoAtual() {
      const response = await api.get(`/Versao/retornar-versao-atual?tipoSistema=1`);

      formik.setFieldValue('versao', response.data.numeroVersao)
      formik.setFieldValue('release', response.data.release)
    }

    getVersaoAtual();

    if (formik.values.statusPedido === 0) {
      setPedidoLiberado(false);
    }
  }, [])

  useEffect(() => {
    if (formik.values.tipoPlano === null || formik.values.faixaFuncionarios === 0) return;
    handleVerificarTotaisClick();
  }, [formik.values.tipoPlano, formik.values.faixaFuncionarios, formik.values.usuariosAdicionais])

  useEffect(() => {
    calcValorParcela();
  }, [formik.values.valorPedido, formik.values.quantidadeParcelas]);

  useEffect(() => {
    if (formik.values.tipoPlano === 1 || formik.values.tipoPlano === 2) {
      formik.setFieldValue('contrato', true);
    }
  }, [formik.values.tipoPlano])

  useEffect(() => {
    setDadosTabs({ 
     distribuidor: formik.values.distribuidor, 
     cliente: formik.values.cliente, 
     nomeFantasia: formik.values.nomeFantasia, 
     numeroSerie: formik.values.numeroSerie })
 }, [
   formik.values.distribuidor,
   formik.values.cliente,
   formik.values.nomeFantasia,
   formik.values.numeroSerie
 ])

 useEffect(() => {
  if (formik.isSubmitting && formik.errors) {
    console.log(formik.errors)
    setMensagem('Preencha os campos obrigatórios.')
    setTipoMensagem('danger');
    setShowMensagem(true)
  }
}, [formik.errors])

  useEffect(() => {
    if (formik.values.tipoPlano === 1 || formik.values.tipoPlano === 2) {
      formik.setFieldValue('bancoDeDados', 1);
    }
  }, [formik.values.tipoPlano])

  if (isLoading) return <Loading />;

  return (
    <Box>
      {showMensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant={tipoMensagem}/>}

      {modalLiberacao.show && 
        <ModalLiberacao 
          pedido={modalLiberacao.pedido} 
        />
      }

      {showModalNS && formik.values.tipoPedido === 1 && listaNS.length > 0 &&
        <ModalNS 
          modalNS={showModalNS} 
          setModalNS={setShowModalNS} 
          numeroSerie={formik.values.numeroSerie} 
          setNumeroSerie={(value) => formik.setFieldValue('numeroSerie', value)} 
          titleModal={formik.values.nomeFantasia} 
          listaNS={listaNS}
          setNSSelecionada={setSelectedNS}
        />
      }

      <Container sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tabs
            sx={{ backgroundColor: 'ghostwhite', boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.05)' }}
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Pedido" {...tabProps(0)} />
            <Tab label="Contrato" {...tabProps(1)} disabled={!formik.values.contrato || !id} />
            <Tab label="Serviço" {...tabProps(2)} disabled={!id} />
          </Tabs>
          <Typography component='h1' variant='h5' sx={{ margin: '0.6rem 13%', fontWeight: 400, color: '#a3a3a3' }}>Pedido - Ponto</Typography>
        </Box>
        <Box>
          <Paper sx={{ backgroundColor: '#F7F8F8', width: '70rem' }} elevation={2}>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', mb: 2, mt: -1 }}>
                {(isShow && formik.values.statusPedido === 0 && formik.values.dataCancelamento === null) &&
                  <Button
                    variant='text'
                    startIcon={<DriveFolderUploadIcon />}
                    onClick={() => setModalLiberacao({ show: true, pedido: { id: id, cliente: { razaoSocial: formik.values.razaoSocial, numeroInscricao: formik.values.numeroInscricao }, contrato: formik.values.contrato, tipoInstalacao: formik.values.tipoInstalacao }} )} 
                    color='success'
                    disabled={nivelUsuario !== '1' || pedidoLiberado}
                  >
                    Liberar Pedido
                  </Button>
                }
                {isShow && formik.values.statusPedido === 0 && (nivelUsuario === '1' || nivelUsuario === '3') &&
                  <Tooltip title='Editar'>
                    <IconButton onClick={() => navigate(`/pedidoponto/${id}`)}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                }
              </Box>
              <Box 
                component='form'
                onSubmit={formik.handleSubmit}
                sx={{display: 'flex', flexDirection: 'column', gap: 1}}
              >
                <FormSectionBox>
                  <FormSectionTitle label="Dados do Pedido" />
                  <Container sx={{display: 'flex', flexDirection: 'column', my: 3, justifyContent: 'center'}}>
                    <BoxBase>
                      <FormControlBase>
                        <InputLabelSelect id="tipoPedido">Tipo de Pedido</InputLabelSelect>
                        <SelectBase 
                          id="tipoPedido"
                          name="tipoPedido"
                          label='Tipo de Pedido'
                          value={formik.values.tipoPedido}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.tipoPedido && Boolean(formik.errors.tipoPedido)}
                          helperText={formik.touched.name && formik.errors.name}
                          isShow={isShow}
                          width='20rem'
                        >
                          <MenuItem value={0}>Licença Nova</MenuItem>
                          <MenuItem value={1}>Atualização</MenuItem>
                          <MenuItem value={2}>Simulação</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <InputLabelSelect id="distribuidor">Revenda</InputLabelSelect>
                        <SelectBase 
                          id="distribuidor" 
                          label="Revenda"
                          name="distribuidor"
                          value={formik.values.distribuidor}
                          onChange={(e) => handleDistribuidorChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.distribuidor && Boolean(formik.errors.distribuidor)}
                          helperText={formik.touched.distribuidor && formik.errors.distribuidor}
                          isShow={isShow}
                          width='20rem'
                        >
                          {distribuidores.map((distribuidor) => (
                            <MenuItem
                              key={distribuidor.id}
                              value={distribuidor.id}
                            >
                              {distribuidor.razaoSocial}
                            </MenuItem>
                          ))}
                        </SelectBase>
                      </FormControlBase>
                      <FormControlBase>
                        <InputLabelSelect id="cliente">Cliente</InputLabelSelect>
                        <SelectBase 
                          id="cliente" 
                          label="Cliente"
                          name="cliente"
                          value={formik.values.cliente}
                          onChange={(e) => handleClienteChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.cliente && Boolean(formik.errors.cliente)}
                          helperText={formik.touched.cliente && formik.errors.cliente}
                          isShow={isShow}
                          width='20rem'
                        >
                          {clientes.map((cliente) => (
                            <MenuItem
                              key={cliente.id}
                              value={cliente.id}
                            >
                              {cliente.razaoSocial} - {cliente.numeroInscricao}
                            </MenuItem>
                          ))}
                        </SelectBase>
                      </FormControlBase>
                    </BoxBase>
                    
                    <BoxBase> 
                      <FormControlBase>
                        <TextFieldBase 
                          label="Nome Fantasia"
                          value={formik.values.nomeFantasia}
                          width='31rem'
                          disabled
                        />
                      </FormControlBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Número Série"
                          name="numeroSerie"
                          value={formik.values.numeroSerie}
                          onChange={formik.handleChange}
                          error={formik.touched.numeroSerie && Boolean(formik.errors.numeroSerie)}
                          helperText={formik.touched.numeroSerie && formik.errors.numeroSerie}
                          width='22rem'
                          textAlign='center'
                          disabled={nivelUsuario !== '1' || isShow}
                        />
                        <ButtonBase 
                          variant="contained"
                          onClick={() => handleSelecionarNS()} 
                          sx={{ marginLeft: -0.25, width: '10rem', fontSize: '0.75rem', maxHeight: '3.5rem' }}
                          disabled={formik.values.tipoPedido === 0 || formik.values.quantidadeLicencas < 2 || isShow}
                        >
                          Selecionar NS
                        </ButtonBase>
                      </FormControlBase>
                    </BoxBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Observações"
                          name="observacoes"
                          value={formik.values.observacoes}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.observacoes && Boolean(formik.errors.observacoes)}
                          helperText={formik.touched.observacoes && formik.errors.observacoes}
                          width='100%'  
                          multiline 
                          rows={4}
                          disabled={isShow}
                        />
                      </FormControlBase>

                      {(formik.values.statusPedido === 2 || formik.values.dataCancelamento !== null) &&
                      <>
                        <br />
                        <BoxBase>
                          <FormControlBase>
                            <TextFieldBase 
                              label="Data do Cancelamento" 
                              name="dataCancelamento"
                              value={formik.values.dataCancelamento}
                              type="date"
                              onChange={formik.handleChange}
                              onBlur={formik.onBlur}
                              error={formik.touched.dataCancelamento && Boolean(formik.errors.dataCancelamento)}
                              helperText={formik.touched.dataCancelamento && formik.errors.dataCancelamento}
                              width='20rem'
                              disabled={isShow}
                            />
                          </FormControlBase>

                          <FormControlBase>
                            <InputLabelSelect id="motivoCancelamentoOpcao">Motivo do Cancelamento</InputLabelSelect>
                            <SelectBase 
                              id='motivoCancelamentoOpcao'
                              name='motivoCancelamentoOpcao'
                              disabled={isShow}
                              onChange={formik.handleChange}
                              onBlur={formik.onBlur}
                              error={formik.touched.motivoCancelamentoOpcao && Boolean(formik.errors.motivoCancelamentoOpcao)}
                              helperText={formik.touched.motivoCancelamentoOpcao && formik.errors.motivoCancelamentoOpcao}
                              label='Motivo do Cancelamento'
                              value={formik.values.motivoCancelamentoOpcao}
                              width='43rem'
                              margin='0 0 0 -5px'
                            >
                              {motivosCancelamento.map((motivo) => (
                                <MenuItem key={motivo.id} value={motivo.id}>
                                  {motivo.descricaoMotivo}
                                </MenuItem>
                              ))}
                            </SelectBase>
                          </FormControlBase>
                        </BoxBase>

                        <br />

                        <FormControlBase>
                          <TextFieldBase 
                            label="Detalhes do Cancelamento"
                            name="motivoCancelamento"
                            onChange={formik.handleChange}
                              onBlur={formik.onBlur}
                              error={formik.touched.motivoCancelamento && Boolean(formik.errors.motivoCancelamento)}
                              helperText={formik.touched.motivoCancelamento && formik.errors.motivoCancelamento}
                            value={formik.values.motivoCancelamento}
                            disabled={isShow}
                            width='100%'  
                            multiline 
                            rows={4}
                          />
                        </FormControlBase>
                      </>
                      }
                  </Container>
                </FormSectionBox>

                <FormSectionBox>
                  <FormSectionTitle label="Dados da Licença" />
                  <Container sx={{display: 'flex', flexDirection: 'column', my: 3}}>
                    <BoxBase>
                      <FormControlBase>
                        <InputLabelSelect  id="tipoPlano">Tipo de Plano</InputLabelSelect>
                        <SelectBase 
                          id="tipoPlano" 
                          label="Tipo de Plano"
                          name="tipoPlano"
                          value={formik.values.tipoPlano}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.tipoPlano && Boolean(formik.errors.tipoPlano)}
                          helperText={formik.touched.tipoPlano && formik.errors.tipoPlano}
                          width='20rem'
                          isShow={isShow}
                        >
                            <MenuItem value={0}>Licenciado</MenuItem>
                            <MenuItem value={1}>SaaS (Rep-C)</MenuItem>
                            <MenuItem value={2}>SaaS (Rep-P)</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <InputLabelSelect id="bancoDeDados">Banco de Dados</InputLabelSelect>
                        <SelectBase 
                          id="bancoDeDados" 
                          label="Banco de Dados"
                          name="bancoDeDados"
                          value={formik.values.bancoDeDados}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.bancoDeDados && Boolean(formik.errors.bancoDeDados)}
                          helperText={formik.touched.bancoDeDados && formik.errors.bancoDeDados}
                          isShow={isShow}
                          width='21rem'
                        >
                          <MenuItem value={1}>MySql</MenuItem>
                          <MenuItem value={2}>SqlServer</MenuItem>
                          <MenuItem value={3}>Oracle</MenuItem>
                        </SelectBase>
                      </FormControlBase>

                      <FormControlBase>
                        <TextFieldBase 
                          label="Início do Faturamento" 
                          name="inicioFaturamento"
                          value={formik.values.inicioFaturamento}
                          type="date"
                          onChange={formik.handleChange}
                          onBlur={formik.onBlur}
                          error={formik.touched.inicioFaturamento && Boolean(formik.errors.inicioFaturamento)}
                          helperText={formik.touched.inicioFaturamento && formik.errors.inicioFaturamento}
                          width='20rem'
                          disabled={isShow || nivelUsuario !== '1'}
                        />
                      </FormControlBase>
                    </BoxBase>

                    <BoxBase>
                      <FormControl>
                        <TextFieldBase 
                          label="Faixa de Funcionários"
                          name="faixaFuncionarios"
                          type="number"
                          value={formik.values.faixaFuncionarios}
                          onChange={(e) => handleFaixaFuncionariosChange(e)}
                          onBlur={formik.handleBlur}
                          error={formik.touched.faixaFuncionarios && Boolean(formik.errors.faixaFuncionarios)}
                          helperText={formik.touched.faixaFuncionarios && formik.errors.faixaFuncionarios}
                          width='32rem'
                          textAlign='center'
                          onKeyDown={() => setIsTyping(true)}
                          inputProps={{
                            min: 10,
                            step: 1,
                          }}
                          disabled={isShow}
                        />
                      </FormControl>

                      <FormControlBase>
                        <TextFieldBase 
                          id="usuariosAdicionais" 
                          label="Usuários Adicionais"
                          name="usuariosAdicionais"
                          type="number"
                          textAlign='center'
                          inputProps={{ inputProps: { min: 0 } }}
                          value={formik.values.usuariosAdicionais}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.usuariosAdicionais && Boolean(formik.errors.usuariosAdicionais)}
                          helperText={formik.touched.usuariosAdicionais && formik.errors.usuariosAdicionais}
                          width='32rem'
                          disabled={isShow}
                        />
                      </FormControlBase>
                    </BoxBase>

                    <FormikProvider value={formik}>
                      <Grid container spacing={2} sx={{ pb: 2, pl: 4, mt: 1, display: 'flex' }}>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="portaria671"
                            checked={formik.values.portaria671}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Portaria 671"
                          />                        
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="integradorREP"
                            checked={formik.values.integradorREP}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Integrador REP"
                          />  
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="moduloEPI"
                            checked={formik.values.moduloEPI}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Módulo EPI"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="acesso"
                            checked={formik.values.acesso}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Acesso"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="folha"
                            checked={formik.values.folha}
                            onChange={formik.handleChange}
                            disabled={isShow}
                            label="Folha"
                          /> 
                        </Grid>
                        <Grid item xs={3}>
                          <CheckboxBase
                            name="contrato"
                            checked={formik.values.contrato}
                            onChange={handleContratoChange}
                            disabled={isShow}
                            label="Contrato"
                          /> 
                        </Grid>
                        {nivelUsuario === '1' && 
                          <Grid item xs={3}>
                            <CheckboxBase
                              name="licenciado"
                              checked={formik.values.licenciado}
                              onChange={formik.handleChange}
                              disabled={isShow}
                              label="Licenciado"
                            />
                          </Grid>
                        }
                      </Grid>
                    </FormikProvider>
                  </Container>
                  </FormSectionBox>
                
                <FormSectionBox>
                  <FormSectionTitle label="Totais" />
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Grid container spacing={2} sx={{ p: 4 }}>
                      <Grid item xs={4}>
                      <FormControl>
                        <CurrencyInput
                          customInput={TextFieldBase}
                          label="Total Pedido"
                          name="valorPedido"
                          value={formik.values.valorPedido}
                          onValueChange={(value) => formik.setFieldValue('valorPedido', value)}
                          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                          disabled={nivelUsuario !== '1' || isShow}
                          textAlign="center"
                          sx={{ mb: 1.5, width: '20rem' }}
                          decimalScale={2}
                          decimalsLimit={2}
                        />
                      </FormControl>

                      </Grid>
                      <Grid item xs={4}>
                      <FormControl>
                        <CurrencyInput
                          customInput={TextFieldBase}
                          label="Valor Mensal"
                          name="valorMensal"
                          value={formik.values.valorMensal}
                          onValueChange={(value) => formik.setFieldValue('valorMensal', value)}
                          intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                          disabled={nivelUsuario !== '1' || isShow}
                          textAlign="center"
                          sx={{ mb: 1.5, width: '20rem' }}
                          decimalScale={2}
                          decimalsLimit={2}
                        />
                      </FormControl>

                      </Grid>
                      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', pb: 2 }}>
                        <ButtonBase variant="outlined" onClick={() => handleVerificarTotaisClick()} startIcon={<AttachMoneyIcon />} disabled={isShow} >
                          Verificar Totais
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={9}>
                        {nivelUsuario === '1' &&
                          <FormControlBase>
                              <InputLabel id="quantidadeParcelas">Quantidade de Parcelas</InputLabel>
                              <SelectBase 
                                id="quantidadeParcelas" 
                                label="Quantidade de Parcelas"
                                name="quantidadeParcelas"
                                value={formik.values.quantidadeParcelas}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.quantidadeParcelas && Boolean(formik.errors.quantidadeParcelas)}
                                helperText={formik.touched.quantidadeParcelas && formik.errors.quantidadeParcelas}
                                width='14rem'
                                isShow={isShow}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                              </SelectBase>
                              <CurrencyInput
                                customInput={TextFieldBase}
                                label="Valor da Parcela (R$)"
                                name="valorParcela"
                                value={formik.values.valorParcela}
                                onValueChange={(value) => formik.setFieldValue('valorParcela', value)}
                                onBlur={formik.handleBlur}
                                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                disabled={isShow}
                                textAlign="center"
                                decimalScale={2}
                                decimalsLimit={2}
                                error={formik.touched.valorParcela && Boolean(formik.errors.valorParcela)}
                                helperText={formik.touched.valorParcela && formik.errors.valorParcela}
                              />
                            </FormControlBase>
                          }
                        </Grid>
                    </Grid>
                  </Box>
                </FormSectionBox>

                { id && formik.values.statusPedido === 0 && 
                  <InscricoesAdicionais clienteId={formik.values.cliente} pedidoId={id} />
                }

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                  {isShow || formik.values.tipoPedido === 2 ? (
                    <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
                      Voltar
                    </ButtonBase>
                  ) : (
                    <>
                      <ButtonBase variant="outlined" onClick={() => navigate('/pedidos')}>
                        Cancelar
                      </ButtonBase>
                      <ButtonBase type="submit" variant="contained" disabled={!isSaveEnabled}>
                        Salvar
                      </ButtonBase>	
                    </>
                  )}
                </Box>
                
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <ContratoTab isShow={isShow} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ServicoTab isShow={isShow || nivelUsuario !== "1"} />
            </CustomTabPanel>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}
